import React from 'react'

import {StaticImage} from 'gatsby-plugin-image'
import {Box,Flex,Heading,Text} from '@chakra-ui/react'
import MySEO from '../components/seo'

const Jahresbuch = () => {

return(
    <div>
      <MySEO
        title="Freie Arbeit Jahrbücher"
        description="Beispiele der Gestaltung von Jahresbüchern"
      />
    <Heading># freie projekte</Heading>



    <Text variant="bildtext"><b>Jahrbücher 2012-2021. </b>Seit neun Jahren produziert hasen oder bein  <b>fotografische Jahrbücher, </b> jede Woche erhält eine Seite bzw. ein Bild. Grundlage sind fotografische Beobachtungen des Alltags mit kurzen Kommentaren. Eine subjektive, manchmal auch "zeitgeistige" Verortung. </Text>
    <Flex>
           <Box width={["100%","100%","49%"]}>
<StaticImage src="../images/titel2_jahrbuch.jpg" alt="titelbild jahrbuch"  />
           </Box>
           <Box alt="Bild Jahresbuch" width={["100%","100%","49%"]}>
<StaticImage src="../images/tableau5_jahrbuch.jpg" alt="titelbild jahrbuch"  />
            </Box>
     </Flex>

     <Flex>
            <Box width={["100%","100%","49%"]}>
<StaticImage src="../images/titel1_jahrbuch.jpg" alt="titelbild jahrbuch"  />

            </Box>
            <Box alt="Bild Jahresbuch" width={["100%","100%","49%"]}>
<StaticImage src="../images/tableau3_jahrbuch.jpg" alt="titelbild jahrbuch"  />

             </Box>
             <Box alt="Bild Jahresbuch" width={["100%","100%","49%"]}>
<StaticImage src="../images/tableau4_jahrbuch.jpg" alt="titelbild jahrbuch"  />

             </Box>
      </Flex>

      <Flex>
             <Box width={["100%","100%","49%"]}>
<StaticImage src="../images/titel0_jahrbuch.jpg" alt="titelbild jahrbuch"  />
             </Box>
             <Box alt="Bild Jahresbuch" width={["100%","100%","49%"]}>
<StaticImage src="../images/tableau2_jahrbuch.jpg" alt="titelbild jahrbuch"  />

              </Box>
              <Box alt="Bild Jahresbuch" width={["100%","100%","49%"]}>

<StaticImage src="../images/tableau6_jahrbuch.jpg" alt="titelbild jahrbuch"  />
              </Box>
        </Flex>
    </div>
)}




  export default Jahresbuch
